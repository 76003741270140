import { marqueeAnnouncementStore } from '@/services/MarqueeAnnouncements/store'
import { notifyError } from '@/services/notify'
import $ from 'jquery'
import _get from 'lodash/get'
import moment from 'moment'
const today = moment()

export const methods = {
  async reload() {
    console.debug('in reload()')

    this.marqueeAnnouncementID = _get(this, '$route.params.id', null)

    if (this.marquee) {
      this.buttonText = 'Update'
      this.formData.announcementHtml = this.marquee.announcementHtml

      const publishDateTemp = _get(this.marquee, 'publishDate', null)
      this.formData.publishDate = new Date(moment(publishDateTemp).format('MM/DD/YYYY'))

      const expirationDateTemp = _get(this.marquee, 'expirationDate', null)
      if (expirationDateTemp !== undefined && expirationDateTemp) {
        this.formData.expirationDate = new Date(moment(expirationDateTemp).format('MM/DD/YYYY'))
      }
    } else {
      this.buttonText = 'Add'
      this.publishDate = today
    }
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.toggle = !this.toggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if (!this.formData) {
      notifyError('There was a problem processing this marquee announcement.')
      return
    }

    if (!this.formData.announcementHtml || this.formData.announcementHtml.length === 0) {
      notifyError('The announcement is required.')
      return
    }

    this.loading = true

    try {
      const payload = {
        hoaID: this.hoaId,
        announcementHtml: _get(this.formData, 'announcementHtml', ''),
        publishDate: _get(this.formData, 'publishDate', ''),
        expirationDate: _get(this.formData, 'expirationDate', null)
      }

      console.log('payload=' + JSON.stringify(payload) + 'marquee=' + this.marquee)

      if (this.marquee) {
        console.debug('in update...')
        payload.marqueeAnnouncementID = this.marquee.marqueeAnnouncementID

        await marqueeAnnouncementStore.dispatch('updateMarqueeAnnouncement', {
          payload,
          done: () => {
            this.closeModal()
          }
        })
      } else {
        await marqueeAnnouncementStore.dispatch('addMarqueeAnnouncement', {
          payload,
          done: () => {
            this.closeModal()
          }
        })
      }
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  }
}
