<template>
  <div hoa-contact-modal id="hoaContactModal" class="section">
    <div class="container">
      <div class="is-size-4">{{ `${buttonText} Marquee Announcement` }}</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="is-12 pt-5">
                  <b-field label="Announcement Html">
                    <vue-editor
                      id="editor"
                      v-model="formData.announcementHtml"
                      maxlength="8000"
                      :editor-toolbar="customToolbar"
                      :required="true"
                    />
                  </b-field>
                </div>
                <div class="column is-12">
                  <b-field label="Publish Date">
                    <b-datepicker
                      style="maxWidth: 500px;"
                      v-model="formData.publishDate"
                      label="Publish Date"
                      :show-week-number="showWeekNumber"
                      placeholder="Click to select Publish Date..."
                      icon="calendar-today"
                      trap-focus
                      editable
                      :required="true"
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="column is-12">
                  <b-field label="Expiration Date">
                    <b-datepicker
                      style="maxWidth: 500px;"
                      v-model="formData.expirationDate"
                      label="Expiration Date"
                      :show-week-number="showWeekNumber"
                      placeholder="Click to select the Expiration Date..."
                      icon="calendar-today"
                      trap-focus
                      editable
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="pt-6 pl-2 pb-6">
                  <button type="submit" class="button is-primary is-rounded">
                    {{ buttonText }}
                  </button>
                </div>
                <div class="pt-6 pb-6">&nbsp;</div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { VueEditor } from 'vue2-editor/dist/vue2-editor.core.js'

export default {
  name: 'MarqueeModal',
  props: {
    marquee: Object
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
<style lang="css">
@import '~vue2-editor/dist/vue2-editor.css';
/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>
