import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { marqueeAnnouncementStore } from '@/services/MarqueeAnnouncements/store'
import moment from 'moment'

export const methods = {
  determineRows: function(list) {
    let filteredList = list

    var rows = filteredList.map(entry => {
      var dateFormat = 'LL'

      let announcementHtml = _get(entry, 'announcementHtml', '')
      if (announcementHtml) {
        announcementHtml = `<span>${announcementHtml}</span>`
      }

      const tempPublishDate = _get(entry, 'publishDate', '')
      let publishDate = tempPublishDate

      try {
        publishDate = moment(tempPublishDate).format(dateFormat)
      } catch (exception) {
        console.error(exception)
      }

      const tempExpirationDate = _get(entry, 'expirationDate', '')
      let expirationDate = tempExpirationDate
      try {
        expirationDate = moment(tempExpirationDate).format(dateFormat)
      } catch (exception) {
        console.error(exception)
      }

      return {
        announcementHtml,
        publishDate,
        expirationDate: tempExpirationDate ? expirationDate : '',
        details: {
          component: Button,
          props: {
            onClick: () => {
              this.editMarquee(entry)
            },
            text: 'Edit'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDeleteMarquee(entry)
            },
            text: 'Delete'
          }
        }
      }
    })

    this.rows = rows
  },

  async confirmDeleteMarquee(entry) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Marquee',
      message: `Are you sure you want to <b>delete</b> this Marquee, "${entry.announcementHtml}"?`,
      confirmText: 'Delete Marquee',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => await this.deleteMarquee(entry.marqueeAnnouncementID)
    })
  },

  async deleteMarquee(marqueeAnnouncementID) {
    try {
      await marqueeAnnouncementStore.dispatch('deleteMarqueeAnnouncements', {
        marqueeAnnouncementID,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    } catch (e) {
      console.debug('There was an issue deleting this marquee announcement.' + e)
    }
  },

  async reload() {
    console.debug('in reload...')
    this.loading = true

    await marqueeAnnouncementStore
      .dispatch('getMarqueeAnnouncementList', {
        hoaID: this.hoaId
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })
  },

  editMarquee(marquee) {
    if (marquee) {
      this.toggle = true
      this.selectedMarquee = marquee
    }
  },

  createMarqueeModal() {
    this.toggle = true
    this.selectedMarquee = null
  }
}
